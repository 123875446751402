import React from "react";
import axios from "axios";
import spinner from "../../../assets/spinner.svg";
// import { RadialChart } from "react-vis";
// import dayjs from "dayjs";
import config from "../../../config";
import supportBadge from "../../../assets/badges/chevron-11.svg";
import Moment from 'react-moment';
import History from "./History";

const Comp = () => {

    const [showSpinner, setShowSpinner] = React.useState(true);
    const [eraData, setEraData] = React.useState();


    const getStats = async () => {
        axios.get(`${config.apiGateway.URL}/stats`).then((res) => {
            if (res && res.data) {

                let apiData = res.data
                if (apiData.dishesReceived && apiData.dishesReceived[0]) {
                    apiData.dishesReceived = apiData.dishesReceived.sort((a, b) => {
                        return b.praiseReceived - a.praiseReceived
                    })
                    apiData.maxReceived = apiData.dishesReceived[0].praiseReceived;
                }
                setEraData(apiData);
                setShowSpinner(false);
            }
        }).catch((e) => {
            console.log(e);
        });
    };


    React.useEffect(() => {
        getStats();
    }, []);

    if (showSpinner) {
        return (
            <section className="is-medium has-text-white">
                <div className="">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-8-desktop is-10 is-offset-1  has-text-centered">
                                <p className="is-size-5 has-text-weight-bold">Loading</p>
                                <div className="spacer"></div>
                                <img alt="spinner" src={spinner} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }



    const header = () => {
        return (
            <>
                <section className="section hero is-medium">
                    <div className="hero-body is-vcentered">
                        <div className="container has-text-right">
                            <div className="columns">
                                <div className="column is-half hero-image">
                                    <div className="hero-box">
                                        <img
                                            src={supportBadge}
                                            alt="AVADO staking hardware for blockchain"
                                            width="400px"
                                        />
                                    </div>
                                </div>

                                <div className="column is-half">

                                    <h1
                                        className="is-size-1 is-size-2-mobile has-text-centered-mobile has-text-light"
                                    >AVADO Support Leaderboard</h1>
                                    <p className="has-text-light">For current Era since <Moment format="YYYY/MM/DD">{eraData.eraBoundaries.start}</Moment>
                                    </p>
                                    <br />
                                    <br />
                                    {/* <table className="table is-fullwidth"> */}
                                    {eraData.dishesReceived.map((receiver, i) => {
                                        return (
                                            // <tr>
                                            //     <td>
                                            <article className="media">
                                                <figure className="media-left">

                                                    <div class="card is-transparent no-shadow">
                                                        <div class="">
                                                            <p className="image is-64x64">
                                                                <img alt={receiver.to} className="is-rounded" src={`${config.apiGateway.URL}/avatar/${receiver.id}`} />
                                                            </p>
                                                        </div>
                                                        {receiver.isexpert && (
                                                            <div class=" is-overlay">
                                                                <img alt="badge" className="image is-32x32" src={supportBadge} />
                                                            </div>
                                                        )}
                                                    </div>

                                                </figure>
                                                <div className="media-content">
                                                    <div className="content has-text-white">
                                                        <strong className="has-text-white">{i + 1}. {receiver.to} &nbsp;</strong>
                                                        <div class="tags is-inline-block">
                                                            {receiver.isexpert && (
                                                                <span class="tag is-rounded">expert</span>
                                                            )}
                                                        </div>

                                                        <progress class="progress is-primary" value={receiver.praiseReceived} max={eraData.maxReceived}></progress>
                                                    </div>
                                                </div>
                                                <div className="media-right">
                                                    <article class="tile is-child has-text-centered">
                                                        <p class="title has-text-white">{receiver.praiseReceived}</p>
                                                        <p class="subtitle has-text-white">Praise</p>
                                                    </article>
                                                    {/* <p className="title has-text-white is-size-4"> praise</p> */}
                                                </div>
                                            </article>
                                            //     </td>
                                            // </tr>
                                        );
                                    })}
                                    {/* </table> */}
                                    <br/><br/><br/>
                                    <p className="is-size-4 is-size-6-mobile has-text-light">
                                        Our crypto friendly support assistance bot carefully tracks praises dished to our community members for doing something extraordinary for someone else.
                                <br />
                                        <b>#RunYourOwnNode</b>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <History />

                {/* <section className="is-medium has-text-white">
                    <h1 className="title is-1 is-spaced has-text-white">AVADO Praisebot</h1>
                    <div className="columns">
                        <div className="column is-half">

                            {eraData && (
                                <>
                                    <h2>Current era {eraData.era}</h2>
                                    <h4>(from {dayjs(eraData.eraBoundaries.start).format('YYYY-MM-DD')} until {dayjs(eraData.eraBoundaries.end).format('YYYY-MM-DD')})</h4>
                                    <RadialChart
                                        showLabels={true}
                                        data={eraData.dishesReceived.map((receiver) => {
                                            return {
                                                angle: receiver.praiseReceived,
                                                label: receiver.to,
                                                subLabel: `${receiver.praiseReceived} praise received`
                                            }
                                        })}
                                        width={300}
                                        height={300} />
                                </>
                            )}
                        </div>

                        <div className="column is-half">
                            <table className="table is-fullwidth">
                                {eraData.dishesReceived.map((receiver, i) => {
                                    return (
                                        <tr>
                                            <td>
                                                <article className="media">
                                                    <figure className="media-left">

                                                        <div class="card is-transparent no-shadow">
                                                            <div class="">
                                                                <p className="image is-64x64">
                                                                    <img alt="" className="is-rounded" src={`${config.apiGateway.URL}/avatar/${receiver.id}`} />
                                                                </p>
                                                            </div>
                                                            {receiver.isexpert && (
                                                                <div class=" is-overlay">
                                                                    <img className="image is-32x32" src={supportBadge} />
                                                                </div>
                                                            )}
                                                        </div>

                                                    </figure>
                                                    <div className="media-content">
                                                        <div className="content has-text-white">
                                                            <strong className="has-text-white">{i + 1}. {receiver.to} &nbsp;</strong>
                                                            <div class="tags is-inline-block">
                                                                {receiver.isexpert && (
                                                                    <span class="tag is-rounded">support expert</span>
                                                                )}
                                                            </div>

                                                            <progress class="progress is-primary" value={receiver.praiseReceived} max={eraData.maxReceived}></progress>
                                                        </div>
                                                    </div>
                                                    <div className="media-right">
                                                        <p className="title has-text-white is-size-4">{receiver.praiseReceived} praise</p>
                                                    </div>
                                                </article>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>

                        </div></div>
                </section> */}
            </>
        )
    }

    return header();

};

export default Comp;