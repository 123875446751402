import React from "react";
import axios from "axios";
import spinner from "../../../assets/spinner.svg";
import config from "../../../config";
import supportBadge from "../../../assets/badges/chevron-11.svg";
import "./History.sass";

const Comp = () => {

    const [showSpinner, setShowSpinner] = React.useState(true);
    const [historyData, setHistoryData] = React.useState([]);


    const getStats = async () => {
        axios.get(`${config.apiGateway.URL}/latest/`).then((res) => {
            if (res && res.data) {
                setHistoryData(res.data.dishesReceived);
                setShowSpinner(false);
            }
        }).catch((e) => {
            console.log(e);
            setShowSpinner(false);
        });
    };


    React.useEffect(() => {
        getStats();
    }, []);

    if (showSpinner) {
        return (
            <section className="is-medium has-text-white">
                <div className="">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-8-desktop is-10 is-offset-1  has-text-centered">
                                <p className="is-size-5 has-text-weight-bold">Loading</p>
                                <div className="spacer"></div>
                                <img alt="spinner" src={spinner} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }



    const header = () => {
        return (
            <>
                <section className="section hero is-medium">
                    <div className="hero-body is-vcentered">
                        <div className="container has-text-right">
                            <div className="columns">
                                {/* <div className="column is-half hero-image">
                                    <div className="hero-box">
                                        <img
                                            src={supportBadge}
                                            alt="AVADO staking hardware for blockchain"
                                            width="400px"
                                        />
                                    </div>
                                </div> */}

                                <div className="column">

                                    <h1
                                        className="is-size-1 is-size-4-mobile has-text-centered has-text-light"
                                    >Most recent praise dished</h1>

                                    <br />

                                    {historyData.map((dish, i) => {
                                        return (
                                            <div class="card  historycard">
                                                <div class="card-image is-closer">

                                                    <div className="has-text-white has-text-centered">
                                                        <strong className="has-text-white">{dish.from}</strong> dished praise to <strong className="has-text-white">{dish.to}</strong>

                                                        <div className="content is-size-3 is-size-4-mobile has-text-centered has-text-white">
                                                            {`...${dish.reason}`}
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="card-content is-overlay  is-faded">

                                                    <div class="level is-mobile">
                                                        <div class="level-item">

                                                            <div class="card is-transparent no-shadow">
                                                                <div class="">
                                                                    <p className="image is-64x64">
                                                                        <img alt={dish.from} className="is-rounded" src={`${config.apiGateway.URL}/avatar/${dish.fromid}`} />
                                                                    </p>
                                                                </div>
                                                                {dish.fromexpert && (
                                                                    <div class=" is-overlay">
                                                                        <img alt="badge" className="image is-32x32" src={supportBadge} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="level-item">
                                                            <div class="card is-transparent no-shadow">
                                                                <div class="">
                                                                    <p className="image is-64x64">
                                                                        <img alt={dish.to} className="is-rounded" src={`${config.apiGateway.URL}/avatar/${dish.toid}`} />
                                                                    </p>
                                                                </div>
                                                                {dish.fromexpert && (
                                                                    <div class=" is-overlay">
                                                                        <img alt="badge" className="image is-32x32" src={supportBadge} />
                                                                    </div>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                {/* 
                                                <article className="media">
                                                    <figure className="media-left has-text-white">
                                                        <div class="card is-transparent no-shadow">
                                                            <div class="">
                                                                <p className="image is-64x64">
                                                                    <img alt={dish.from} className="is-rounded" src={`${config.apiGateway.URL}/avatar/${dish.fromid}`} />
                                                                </p>
                                                            </div>
                                                            {dish.fromexpert && (
                                                                <div class=" is-overlay">
                                                                    <img alt="badge" className="image is-32x32" src={supportBadge} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </figure>
                                                  
                                                    <div className="media-right is-pulled-right">
                                                        <div class="card is-transparent no-shadow">
                                                            <div class="">
                                                                <p className="image is-64x64">
                                                                    <img alt={dish.to} className="is-rounded" src={`${config.apiGateway.URL}/avatar/${dish.toid}`} />
                                                                </p>
                                                            </div>
                                                            {dish.toexpert && (
                                                                <div class="is-overlay">
                                                                    <img alt="badge" className="image is-32x32" src={supportBadge} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </article> */}

                                            </div>
                                        );
                                    })}
                                    <br /><br />
                                    <p className="is-size-4 is-size-6-mobile has-text-light">
                                        Be kind to eachother, and<br />
                                        <b>#RunYourOwnNode</b>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    return header();

};

export default Comp;