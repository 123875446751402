const configs = {
    development: {
      name: 'dev',
      apiGateway: {
        URL: 'http://localhost:5003',
      }
    },

    staging: {
        name: 'staging',
        apiGateway: {
          URL: 'https://staging.supportbot.avado.cloud',
        }
      },
  

    production: {
      name: 'prod',
      apiGateway: {
        URL: 'https://supportbot.avado.cloud',
      }
    },
  };
  const config = process.env.REACT_APP_STAGE
    ? configs[process.env.REACT_APP_STAGE]
    : configs.development;
  
  module.exports = config;
  